(function ($) {

    $(document).ready(function () {

        /***Add to favorites***/

        $(document).on("touch click", ".favorites", function (e) {
            e.preventDefault();
            let _this = $(this);
            let itemId = _this.data('itemId');

            $.ajax({
                method: "POST",
                url: `${window.location.origin}/${window.globalLang}/addToWithList`,
                data: {
                    itemId: itemId
                }
            })
                .success(function (response) {
                    $('#wish-count').html(response.count > 0 ? (response.count < 10 ? response.count : '9+') : '');

                    if (response.count > 0) {
                        $('#wish-count').addClass('show');
                    } else {
                        $('#wish-count').removeClass('show');
                    }

                    if (response.status)
                        _this.toggleClass('active');
                });
        });

        $(document).on("click", ".deleteFavProduct", function (e) {
            e.preventDefault();

            let _this = $(this);
            let itemId = _this.data('itemId');

            $.ajax({
                method: "POST",
                url: `${window.location.origin}/${window.globalLang}/destroyFromWishList`,
                data: {
                    itemId: itemId
                }
            })
                .success(function (response) {

                    if (response.status && response.view.length) {
                        $('.wishListContainer .products-list').html(response.view);

                        $('#wish-count').html(response.count > 0 ? (response.count < 10 ? response.count : '9+') : '');
                        if (response.count > 0) {
                            $('#wish-count').addClass('show');
                        } else {
                            $('#wish-count').removeClass('show');
                        }

                        let pushUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
                        window.history.pushState({path: pushUrl}, '', pushUrl);
                    }

                });
        });

        /***Add to favorites***/

    })

})(jQuery);