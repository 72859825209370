(function ($) {

    $(document).ready(function () {
        let productArDiscountBlock = $('.best-product');

        if (productArDiscountBlock.length) {
            productArDiscountBlock.slick({
                infinite: true,
                dots: true,
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1201,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 750,
                        settings: {
                            slidesToShow: 2.5,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 620,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 380,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }
    })

})(jQuery);