// (function($) {
//
// 	$(document).ready(function() {
//
//         /*** Show all description text ***/
//         let overflowContainer = $(".aboutProductContainer .overflowContainer");
//         let textContainer = $(".aboutProductContainer .overflowContainer p");
//
//         if( overflowContainer.height() < textContainer.height() - 1 ){
//             $(overflowContainer).siblings(".showAllbtn").addClass("visible");
//
//             $(".aboutProductContainer .overflowContainer").siblings(".showAllbtn").on("click", function(){
//
//                 $(this).siblings(".overflowContainer").toggleClass("fullHeight");
//                 $(this).children().first().toggle();
//                 $(this).children().last().toggle();
//             })
//         }
//
//
//     })
//
// })(jQuery);