(function ($) {

    $(document).ready(function () {

        let mainSlider = $('.mainProductDetail .sliderContainer .mainSlider');
        let secondarySlider = $('.mainProductDetail .sliderContainer .secondarySlider');

        if (mainSlider.length)
            mainSlider.slick({
                asNavFor: '.secondarySlider',
                slidesToShow: 1,
                slidesToScroll: 1,
                draggable: false,
                arrows: true,
            });

        if (secondarySlider.length)
            secondarySlider.slick({
                asNavFor: '.mainSlider',
                slidesToShow: 5,
                slidesToScroll: 1,
                focusOnSelect: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

    })

})(jQuery);