;(function ($) {

    $(document).ready(function () {

        $(document).on("keyup click", ".update-product-quantity.update-cart", function (evt) {
            let _this = $(this),
                quantity = window.updateProductQuantity(evt, _this),
                deliveryMethodsBlock = $('#delivery-methods');

            if (!quantity || !$.isNumeric(quantity))
                return false;

            $.ajax({
                method: "POST",
                url: `${window.location.origin}/${window.globalLang}/updateCart`,
                data: {
                    itemId: _this.data('id'),
                    quantity: quantity,
                    shippingMethodId: deliveryMethodsBlock.find('input[type=radio][name=shipping_method]:checked').val(),
                    shippingZoneId: deliveryMethodsBlock.data('shippingZoneId')
                }
            })
                .success(function (response) {

                    if (response.msg && !response.validator)
                        toastr[response.msg.type](response.msg.e);

                    if (response.couponMsg)
                        toastr[response.couponMsg.type](response.couponMsg.e);

                    if (response.status) {
                        updateCartContent(_this, response);

                        if (response.couponMsg)
                            $('#selected-coupon-block .destroy-coupon').trigger('click');

                    } else {

                        if (response.validator && response.msg) {
                            $.each(response.msg.e, function (ObjNames, ObjValues) {
                                toastr.warning(ObjValues);
                            });
                        }
                    }
                })
                .fail(function (response) {
                    let jsonResponse = response.responseJSON;

                    if (jsonResponse.msg)
                        toastr[jsonResponse.msg.type](jsonResponse.msg.e);
                });

        });

        $(document).on('click', '.destroy-cart-product', function (e) {
            e.preventDefault();

            let _this = $(this),
                itemId = _this.data('itemId'),
                deliveryMethodsBlock = $('#delivery-methods');

            $.ajax({
                method: "POST",
                url: `${window.location.origin}/${window.globalLang}/destroyCartProduct`,
                data: {
                    itemId: itemId,
                    shippingMethodId: deliveryMethodsBlock.find('input[type=radio][name=shipping_method]:checked').val(),
                    shippingZoneId: deliveryMethodsBlock.data('shippingZoneId')
                }
            })
                .success(function (response) {

                    if (response.msg)
                        toastr[response.msg.type](response.msg.e);

                    if (response.couponMsg)
                        toastr[response.couponMsg.type](response.couponMsg.e);

                    if (response.status) {
                        updateCartContent(_this, response, 'destroy');
                        if (response.couponMsg)
                            $('#selected-coupon-block .destroy-coupon').trigger('click');
                    }
                })
                .fail(function (response) {
                    let jsonResponse = response.responseJSON;

                    if (jsonResponse.msg)
                        toastr[jsonResponse.msg.type](jsonResponse.msg.e);
                });
        });

        $(document).on('submit', '#checkout-form', function (e) {
            e.preventDefault();
            let form = $(this),
                submitBtn = form.find(`button[type="submit"][data-form-id="${form.attr('id')}"]`),
                serializedForm = form.find("select, textarea, input").serializeArray();

            form.find('input, textarea, select').removeClass('error');
            form.find('div.errorMsg').remove();

            $.ajax({
                method: "POST",
                url: form.attr('action'),
                beforeSend: function () {
                    submitBtn.addClass('loading');
                },
                data: serializedForm
            })
                .success(function (response) {
                    window.successErrorAjaxBtn(submitBtn, response.status);

                    if (response.msg && !response.validator)
                        toastr[response.msg.type](response.msg.e);

                    if (response.status) {
                        if (response.redirect)
                            window.location.href = response.redirect;

                        form.find('input, textarea, select').val('');
                        form.find('input, textarea, select').removeClass('error');

                    } else {

                        if (response.isCouponError && response.msg && response.msg.e) {
                            let selectedCouponBlock = $('#selected-coupon-block');
                            selectedCouponBlock.find('.coupon').addClass('error');
                            selectedCouponBlock.siblings('.errorCouponMsg').remove();
                            selectedCouponBlock.after(`<div class="errorCouponMsg">${response.msg.e}</div>`);

                            $('html, body').animate({
                                scrollTop: selectedCouponBlock.offset().top - 20
                            }, 500);
                        }


                        if (response.scrollToProducts && response.outOfStockProducts.length) {
                            let scrollToFirstProduct = [];

                            response.outOfStockProducts.map((itemId, index) => {
                                let purchaseCardBlock = $(`.cartPage .purchaseCard[data-item-id="${itemId.id}"]`);
                                if (index === 0)
                                    scrollToFirstProduct = purchaseCardBlock;

                                if (!$.isNumeric(itemId.qty))
                                    purchaseCardBlock.addClass('outOfStock').find('.qty').removeClass('decreasedStock');
                                else {
                                    purchaseCardBlock.removeClass('outOfStock').find('.qty').addClass('decreasedStock').data('max-value', itemId.qty);

                                    if (itemId.qtyMsg)
                                        purchaseCardBlock.find('.qty').attr('title', itemId.qtyMsg);
                                }
                            });

                            if (scrollToFirstProduct.length)
                                $('html, body').animate({
                                    scrollTop: scrollToFirstProduct.offset().top - 20
                                }, 500, function () {
                                    $('.cartPage .purchaseCard.outOfStock').find('.deletebtn').addClass('animated bounce delay-1s');

                                    setTimeout(function () {
                                        $('.cartPage .purchaseCard.outOfStock').find('.deletebtn').removeClass('animated bounce delay-1s');
                                    }, 3000);

                                });
                        }


                        if (response.validator && response.msg) {
                            $.each(response.msg.e, function (ObjNames, ObjValues) {

                                let field = form.find("[name='" + ObjNames + "']");

                                if (field.length > 0) {
                                    field.addClass('error');
                                    if (field.prop("tagName") === 'SELECT') {
                                        field.closest('.selectContainer').append('<div class="errorMsg ' + ObjNames + '">' + ObjValues + '</div>');
                                    } else {
                                        if (field.attr('type') === 'radio') {
                                            field = field.last();
                                            field.siblings('label').after('<div class="errorMsg ' + ObjNames + '">' + ObjValues + '</div>');
                                        } else
                                            field.after('<div class="errorMsg ' + ObjNames + '">' + ObjValues + '</div>');
                                    }

                                    // $('html, body').animate({
                                    //     scrollTop: field.offset().top - 20
                                    // }, 500);
                                }
                            });
                        }

                    }

                })
                .fail(function (response) {
                    window.successErrorAjaxBtn(submitBtn, false);

                    let jsonResponse = response.responseJSON;

                    if (jsonResponse.msg)
                        toastr[jsonResponse.msg.type](jsonResponse.msg.e);
                });
        });

        $(document).on('submit', '#coupon-form', function (e) {
            e.preventDefault();

            applyCoupon($(this));
        });

        $(document).on('click', '.destroy-coupon', function () {
            let selectedCouponBlock = $('#selected-coupon-block'),
                priceContainer = $('.totalPriceContainer'),
                subtotalCouponPrice = $('#cart-subtotal-coupon-price'),
                subtotalBlock = $('#cart-subtotal-price'),
                deliveryMethodsBlock = $('#delivery-methods'),
                cartTotalPriceBlock = $('.cart-total-price'),
                shippingErrorsBlock = $('#shipping-errors'),
                clientCountrySelect = $('#client-country'),
                clientRegionSelect = $('#client-region');

            $.ajax({
                method: "POST",
                url: `${window.location.origin}/${window.globalLang}/destroyCoupon`,
                beforeSend: function () {
                    priceContainer.addClass('loading');
                },
                data: {
                    couponId: $(this).data('couponId'),
                    shippingMethodId: deliveryMethodsBlock.find('input[type=radio][name=shipping_method]:checked').val(),
                    shippingZoneId: deliveryMethodsBlock.data('shippingZoneId'),
                    countryId: clientCountrySelect.val(),
                    regionId: clientRegionSelect.val()
                }
            })
                .success(function (response) {
                    priceContainer.removeClass('loading');

                    if (response.msg && !response.validator)
                        toastr[response.msg.type](response.msg.e);

                    if (response.status) {
                        selectedCouponBlock.data('couponId', '').find('.destroy-coupon').addClass('hidden');
                        selectedCouponBlock.find('.coupon').removeClass('error').html('');
                        selectedCouponBlock.siblings('.errorCouponMsg').remove();
                        selectedCouponBlock.siblings('.freeShippingMsg').remove();

                        if (subtotalCouponPrice.length)
                            subtotalCouponPrice.html('').closest('.subtotal-without-coupon-block').addClass('hidden');

                        if (response.cartSubtotal && response.cartSubtotalStr)
                            subtotalBlock.html(response.cartSubtotalStr);

                        if (response.cartTotal && response.cartTotalStr) {
                            cartTotalPriceBlock.html(response.cartTotalStr);
                            cartTotalPriceBlock.data('no-shipping-amount', response.cartSubtotalStr);
                            cartTotalPriceBlock.data('shipping-amount', response.cartTotalStr);
                        }

                        if (response.cartSubtotal && response.cartSubtotalStr) {
                            if (response.cartSubtotalWithCoupon && response.cartSubtotalWithCouponStr && response.cartSubtotal > response.cartSubtotalWithCoupon) {
                                $('#cart-subtotal-price').html(response.cartSubtotalWithCouponStr);
                                $('#cart-subtotal-coupon-price').html(response.cartSubtotalStr);
                                $('#cashContainer-total-price').html(response.cartTotal + ' ₽');
                                $('#yandexContainer-total-price').html(response.cartTotal + ' ₽');
                                console.log('intra')
                            } else {
                                if (response.cartSubtotal >= $('#total-price-delivery').attr('data-price')) {
                                    $('#total-price-delivery').html('Бесплатно');
                                } else {
                                    $('#total-price-delivery').html(response.delivery + ' ₽');
                                }
                                console.log(cartTotal)
                                $('#cart-subtotal-price').html(response.cartSubtotalStr);
                                $('#cashContainer-total-price').html(response.cartTotal + ' ₽');
                                $('#yandexContainer-total-price').html(response.cartTotal + ' ₽');
                            }
                        }

                        if (deliveryMethodsBlock.length) {
                            deliveryMethodsBlock.data('shippingZoneId', response.shippingZoneId);
                            deliveryMethodsBlock.html(response.shippingMethodsView);
                        }

                        if (shippingErrorsBlock.length)
                            shippingErrorsBlock.html(response.errorsView);
                    }

                })
                .fail(function (response) {
                    priceContainer.removeClass('loading');

                    let jsonResponse = response.responseJSON;

                    if (jsonResponse.msg)
                        toastr[jsonResponse.msg.type](jsonResponse.msg.e);
                });
        });
    });

    function updateCartContent(that, response, event = 'update') {
        let deliveryMethodsBlock = $('#delivery-methods'),
            cartTotalPriceBlock = $('.cart-total-price');

        $('#cart-count').html(response.cartCount > 0 ? (response.cartCount < 10 ? response.cartCount : '9+') : '');

        if(response.cartCount > 0) {
            $('#cart-count').addClass('show');
        }
        else {
            $('#cart-count').removeClass('show');
        }


        if (response.thumbnail && $('#cart-preview').length)
            $('#cart-preview').html(response.thumbnail);

        if (response.destroyCart && $('.emptyCartContainer').length) {
            $('.purchaseContainer').fadeOut(300, function () {
                $(this).remove();
            });
            $('.totalPriceContainer').fadeOut(300, function () {
                $(this).remove();
            });
            $('.cartTabs').fadeOut(300, function () {
                $(this).remove();
            });

            setTimeout(function () {
                $('.emptyCartContainer').removeClass('hidden');
            }, 300);
        }

        if (response.cartSubtotal && response.cartSubtotalStr) {
            if (response.cartSubtotalWithCoupon && response.cartSubtotalWithCouponStr && response.cartSubtotal > response.cartSubtotalWithCoupon) {
                $('#cart-subtotal-price').html(response.cartSubtotalWithCouponStr);
                $('#cart-subtotal-coupon-price').html(response.cartSubtotalStr);
                $('#cashContainer-total-price').html(response.cartTotal + ' ₽');
                $('#yandexContainer-total-price').html(response.cartTotal + ' ₽');
                console.log('intra')
            } else {
                if (response.cartSubtotal >= $('#total-price-delivery').attr('data-price')) {
                    $('#total-price-delivery').html('Бесплатно');
                } else {
                    $('#total-price-delivery').html(response.delivery + ' ₽');
                }
                console.log(cartTotal)
                $('#cart-subtotal-price').html(response.cartSubtotalStr);
                $('#cashContainer-total-price').html(response.cartTotal + ' ₽');
                $('#yandexContainer-total-price').html(response.cartTotal + ' ₽');
            }
        }

        if (response.cartTotal && response.cartTotalStr) {
            cartTotalPriceBlock.html($(".cartTabs .navTabs .navItem input[type=radio][name=checkout_type_shipping]:checked").val() === 'pickup' ? response.cartSubtotalWithCouponStr : response.cartTotalStr);
            cartTotalPriceBlock.data('no-shipping-amount', response.cartSubtotalWithCouponStr);
            cartTotalPriceBlock.data('shipping-amount', response.cartTotalStr);
        }

        if (event === 'update') {
            if (response.productQtyPriceStr)
                that.closest('.qty').removeClass('decreasedStock').attr('title', '').next('.totalPrice').html(response.productQtyPriceStr);
        } else if (event === 'destroy' && $('.cartPage .purchaseCard').length) {
            if (that.data('cartThumbnail')) {
                $(`.destroy-cart-product[data-item-id="${that.data('itemId')}"]`).closest('.purchaseCard').fadeOut(300, function () {
                    $(this).remove();
                });
            } else {
                that.closest('.purchaseCard').fadeOut(300, function () {
                    $(this).remove();
                });
            }

            if (deliveryMethodsBlock.length)
                deliveryMethodsBlock.find('input[type=radio][name=shipping_method]').first().prop('checked', true);
        }


        return true;
    }

    function applyCoupon(form) {
        let submitBtn = form.find(`button[type="submit"][data-form-id="${form.attr('id')}"]`),
            serializedForm = form.find("input").serializeArray(),
            selectedCouponBlock = $('#selected-coupon-block'),
            deliveryMethodsBlock = $('#delivery-methods'),
            shippingErrorsBlock = $('#shipping-errors'),
            cartTotalPriceBlock = $('.cart-total-price'),
            clientCountrySelect = $('#client-country'),
            clientRegionSelect = $('#client-region');

        serializedForm.push({
            name: 'shippingMethodId',
            value: deliveryMethodsBlock.find('input[type=radio][name=shipping_method]:checked').val()
        });
        serializedForm.push({name: 'shippingZoneId', value: deliveryMethodsBlock.data('shippingZoneId')});
        serializedForm.push({name: 'countryId', value: clientCountrySelect.val()});
        serializedForm.push({name: 'regionId', value: clientRegionSelect.val()});

        form.find('input').removeClass('error');
        form.find('div.errorMsg').remove();

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            beforeSend: function () {
                submitBtn.addClass('loading');
            },
            data: serializedForm
        })
            .success(function (response) {
                window.successErrorAjaxBtn(submitBtn, response.status);

                if (response.msg && !response.validator)
                    toastr[response.msg.type](response.msg.e);

                if (response.status) {
                    form.find('input').val('');
                    form.find('input').removeClass('error');

                    if (response.couponId && response.couponCode && selectedCouponBlock.length) {
                        selectedCouponBlock.find('.coupon').html(response.couponCode);
                        selectedCouponBlock.find('.destroy-coupon').removeClass('hidden').data('couponId', response.couponId);
                    }

                    if (!response.freeShippingCoupon && response.cartSubtotalStr && response.cartOldSubtotalStr) {
                        $('#cart-subtotal-price').html(response.cartSubtotalStr);
                        $('#cart-subtotal-coupon-price').html(response.cartOldSubtotalStr).closest('.subtotal-without-coupon-block').removeClass('hidden');
                    }

                    if (response.freeShippingCoupon && response.freeShippingCouponMsg) {
                        selectedCouponBlock.siblings('.freeShippingMsg').remove();
                        selectedCouponBlock.after(`<div class="freeShippingMsg">${response.freeShippingCouponMsg}</div>`);
                    }

                    if (response.cartTotal && response.cartTotalStr) {
                        cartTotalPriceBlock.html(response.cartTotalStr);
                        cartTotalPriceBlock.data('no-shipping-amount', response.cartSubtotalStr);
                        cartTotalPriceBlock.data('shipping-amount', response.cartTotalStr);
                    }

                    if (deliveryMethodsBlock.length) {
                        deliveryMethodsBlock.data('shippingZoneId', response.shippingZoneId);
                        deliveryMethodsBlock.html(response.shippingMethodsView);
                    }

                    if (shippingErrorsBlock.length)
                        shippingErrorsBlock.html(response.errorsView);

                    if (response.cartSubtotal && response.cartSubtotalStr) {
                        if (response.cartSubtotalWithCoupon && response.cartSubtotalWithCouponStr && response.cartSubtotal > response.cartSubtotalWithCoupon) {
                            $('#cart-subtotal-price').html(response.cartSubtotalWithCouponStr);
                            $('#cart-subtotal-coupon-price').html(response.cartSubtotalStr);
                            $('#cashContainer-total-price').html(response.cartTotal + ' ₽');
                            $('#yandexContainer-total-price').html(response.cartTotal + ' ₽');
                            console.log('intra')
                        } else {
                            if (response.cartSubtotal >= $('#total-price-delivery').attr('data-price')) {
                                $('#total-price-delivery').html('Бесплатно');
                            } else {
                                $('#total-price-delivery').html(response.delivery + ' ₽');
                            }
                            console.log(cartTotal)
                            $('#cart-subtotal-price').html(response.cartSubtotalStr);
                            $('#cashContainer-total-price').html(response.cartTotal + ' ₽');
                            $('#yandexContainer-total-price').html(response.cartTotal + ' ₽');
                        }
                    }

                } else {
                    if (response.validator && response.msg) {
                        $.each(response.msg.e, function (ObjNames, ObjValues) {

                            let field = form.find("[name='" + ObjNames + "']");

                            if (field.length > 0) {
                                field.addClass('error');
                                if (field.prop("tagName") === 'SELECT') {
                                    field.closest('.selectContainer').append('<div class="errorMsg ' + ObjNames + '">' + ObjValues + '</div>');
                                } else {
                                    if (field.attr('type') === 'radio') {
                                        field = field.last();
                                        field.siblings('label').after('<div class="errorMsg ' + ObjNames + '">' + ObjValues + '</div>');
                                    } else
                                        field.after('<div class="errorMsg ' + ObjNames + '">' + ObjValues + '</div>');
                                }
                            }
                        });
                    }
                }

            })
            .fail(function (response) {
                window.successErrorAjaxBtn(submitBtn, false);

                let jsonResponse = response.responseJSON;

                if (jsonResponse.msg)
                    toastr[jsonResponse.msg.type](jsonResponse.msg.e);
            });
    }



    $(".continue").click(function (e) {

        $(".custom-button").addClass("hiden-class");
        $(".show-elements").addClass("show-class");// add class
    });

      //focus buton disable active (coupnon)
      $('#input-coupon').on('keyup', function () {

        if ( $(this).val().length > 3) {
            $('#button-coupon').attr('disabled', false)
        } else {
            $('#button-coupon').attr('disabled', true)
        }
    
    });

})
(jQuery);