;(function ($) {

    $(document).ready(function () {

        let form = $('#add-to-cart-form');

        if (form.length)
            updateProductAttributes($(this), form, false);

        $(document).on('change', '.add-to-cart-form select, .add-to-cart-form input:radio', function () {
            let _this = $(this);

            _this.parents('.attribute-item-container').find('.attribute-item-wrap').removeClass('active');
            _this.parents('.attribute-item-wrap').addClass('active');

            updateProductAttributes(_this, form);

        });

        $(document).on("keyup click", ".update-product-quantity:not(.update-cart)", function (evt) {
            let _this = $(this);
            window.updateProductQuantity(evt, _this);
        });

        $(document).on('click', '.submit-add-to-cart-btn', function (e) {


            e.preventDefault();
            addToCartForm(this);
        });
    });

    function addToCartForm(parentThat) {


        if (!(parentThat instanceof jQuery))
            parentThat = $(parentThat);

        let formId = parentThat.data('form-id');
        let form = $('#' + formId);

        if (form.length < 1) {
            return;
        }

        let submitBtn = parentThat;


        form.submit(function (event) {
            event.preventDefault();
        });

        let serializedForm = $(form).find("select, input").not('input[type="hidden"][name="variations"]').serializeArray();
        serializedForm.push({name: 'action', value: submitBtn.data('action')});

        form.find('input, textarea').removeClass('error');
        form.find('div.errorMsg').remove();

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            beforeSend: function () {
                submitBtn.addClass('loading');
            },
            data: serializedForm,
            success: function (response) {
                window.successErrorAjaxBtn(submitBtn, response.status);

                if (response.msg && !response.validator){
                    toastr[response.msg.type](response.msg.e);
                }

                if (response.status) {
                    $('#cart-count').html(response.count > 0 ? (response.count < 10 ? response.count : '9+') : '');

                    if (response.count > 0) {
                        $('#cart-count').addClass('show');
                    } else {
                        $('#cart-count').removeClass('show');
                    }
                    if(response.msg.e == 'Seller will contact you in few minutes!'){
                        $('.hide-block').css('display','none');
                        $('.show-block').css('display','block');
                    }

                    if (response.thumbnail && $('#cart-preview').length)
                        $('#cart-preview').html(response.thumbnail);

                } else {
                    if (response.validator && response.msg) {
                        $.each(response.msg.e, function (ObjNames, ObjValues) {

                            let field = form.find("[name='" + ObjNames + "']");

                            if (field.length > 0) {
                                field.addClass('error');
                                field.after('<div class="errorMsg ' + ObjNames + '">' + ObjValues + '</div>');
                            }
                        });
                    }
                }
            },
            error: function (response) {
                window.successErrorAjaxBtn(submitBtn, false);

                let jsonResponse = response.responseJSON;

                if (jsonResponse.msg)
                    toastr[jsonResponse.msg.type](jsonResponse.msg.e);
            }
        });

    }

    function updateProductAttributes(that, form, onFormChange = true) {
        let variationsStr = form.find('input[type=hidden][name=variations]').val();

        if (variationsStr === '')
            return false;

        let allVariations = JSON.parse(variationsStr);

        if (!Object.keys(allVariations).length)
            return false;

        if (onFormChange && that.prop('tagName') !== 'INPUT' && that.prop('tagName') !== 'SELECT')
            return false;

        let allSelectedAttributesIds = [],
            variations = allVariations.variations,
            variationsArr = Object.keys(variations),
            unavailableVariationsAttrIds = [],
            availableVariationsAttrIds = [],
            allAttributesArr = [],
            serializeForm = form.serializeArray(),
            allFormWraps = form.find('.attribute-item-wrap'),
            formAttributesFields = allFormWraps.closest('.attribute-item-container'),
            allAttributesArrIds = [];

        /** Get all selected attributes*/

        if (serializeForm.length)
            serializeForm.map((item) => {
                if (item.name.startsWith('attributes'))
                    allSelectedAttributesIds.push(parseInt(item.value));
            });

        allSelectedAttributesIds = allSelectedAttributesIds.filter(arrUnique);

        /** END Get all selected attributes*/

        /** Update all attributes*/

        if (onFormChange)
            updateInputsAndSelects(that, allFormWraps);

        /** END Update all attributes*/

        if (variationsArr.length) {

            /** Get all attributes from all variations*/

            variationsArr.map((variationId) => {
                let item = variations[variationId],
                    attributes = item.attributes;

                if (attributes instanceof Array) {
                    attributes.map(attrs => {
                        allAttributesArrIds.push(Object.values(attrs));
                        allAttributesArr.push(attrs);
                    });
                } else {
                    allAttributesArrIds.push(Object.values(attributes));
                    allAttributesArr.push(attributes);
                }
            });

            /** END Get all attributes from all variations*/

            /** Get all variations*/

            let chosenAttributes = getChosenAttributes(formAttributesFields),
                currentAttributes = chosenAttributes.data,
                checkIfAllAttrIsCheeked = chosenAttributes.count === chosenAttributes.chosenCount;

            formAttributesFields.each((key, item) => {
                let currentAttrName = $(item).data('itemSlug');

                let checkAttributes = $.extend(true, {}, currentAttributes);
                checkAttributes[currentAttrName] = '';

                let variations = findMatchingVariations(allAttributesArr, checkAttributes);

                for (let num in variations) {
                    if (typeof (variations[num]) !== 'undefined') {
                        let variationAttributes = variations[num];

                        for (let attr_name in variationAttributes) {
                            if (variationAttributes.hasOwnProperty(attr_name)) {
                                let attr_val = variationAttributes[attr_name];

                                if (attr_name === currentAttrName)
                                    availableVariationsAttrIds.push(attr_val);
                            }
                        }
                    }
                }
            });


            allAttributesArrIds = allAttributesArrIds.flat().filter(arrUnique);
            // console.log(allAttributesArrIds, allAttributesArrIds.reduce((acc, val) => acc.concat(val), []).filter(arrUnique));
            availableVariationsAttrIds = availableVariationsAttrIds.filter(arrUnique);
            unavailableVariationsAttrIds = arrDiff(allAttributesArrIds, availableVariationsAttrIds);

            /** Get all variations*/

            if (unavailableVariationsAttrIds.length) {
                unavailableVariationsAttrIds.map((itemId) => {
                    allFormWraps.find(`input[type=radio][value="${itemId}"]`).siblings('label').addClass('disabled');
                    allFormWraps.find(`select option[value="${itemId}"]`).addClass('disabled');
                });

            }

            // if (checkIfAllAttrIsCheeked && onFormChange)
            //     manipulateWithVariation();

            manipulateWithVariation(!(checkIfAllAttrIsCheeked && onFormChange), !onFormChange);
        }
    }

    function manipulateWithVariation(reset, docReady = false) {
        let currVariation = getCurrVariation();
        reset = !(!reset || typeof reset === undefined);

        if (!Object.keys(currVariation).length && !reset)
            return false;

        if (Object.values(currVariation).length && !currVariation.price)
            return false;

        let infoProductStockBlock = $('#info-product-stock'),
            priceProductBlock = $('#price-product-block'),
            countItemsBlock = $('#count-items-block'),
            countItemsInput = $('#count-items'),
            submitAddToCartBtn = $('.submit-add-to-cart-btn'),
            descriptionProductBlock = $('#product-description-block'),
            galleryProductBlock = $('#product-gallery-block'),
            variationIdInput = $('input[type="hidden"][name="variation_id"]'),
            priceBlock = '',
            allFormContainers = $('#add-to-cart-form').find('.attribute-item-container'),
            domDescriptionTitle = '',
            infoProductSaleBlock = $('#info-product-sale');

        if (infoProductStockBlock.length)
            infoProductStockBlock.toggleClass('outOfStock', !currVariation.stock_qty).find('span').text(currVariation.stock);

        if (priceProductBlock.length && currVariation.price) {
            if (currVariation.price)
                priceBlock += `<span class="price ${currVariation.sale_price ? 'border' : ''}">${currVariation.sale_price ? currVariation.sale_price : currVariation.price}</span>`;

            if (currVariation.sale_price) {
                priceBlock += `<span class="sale-price">${currVariation.price}</span>`;

                if (currVariation.productPromotionPercentage && infoProductSaleBlock.length)
                    infoProductSaleBlock.html(`<span>-${currVariation.productPromotionPercentage}%</span>`);
            } else
                infoProductSaleBlock.html('');

            priceProductBlock.html(priceBlock);
        }

        if (countItemsBlock.length && countItemsInput.length) {
            countItemsBlock.toggleClass('hidden', !currVariation.stock_qty);
            countItemsInput.data('max-value', $.isNumeric(currVariation.stock_qty) ? currVariation.stock_qty : '').val(1);
        }

        if (submitAddToCartBtn.length && variationIdInput.length) {

            submitAddToCartBtn.prop('disabled', (!currVariation.stock_qty)).toggleClass('disabled', (!currVariation.stock_qty));
            if (!docReady)
                variationIdInput.val(!reset ? currVariation.id : '');
        }

        if (descriptionProductBlock.length) {
            if (allFormContainers.length)
                allFormContainers.map((index, item) => {
                    if ($(item).data('itemType') === 'select') {
                        let selectedTxt = $(item).find('select option:selected:not(option[value=""])').text();
                        if (selectedTxt)
                            domDescriptionTitle += ` ${$(item).siblings('.title').text()} ${selectedTxt} `;
                    } else {
                        let selectedTxt = $(item).find('input[type=radio]:checked').siblings('label').attr('title');
                        if (selectedTxt)
                            domDescriptionTitle += ` ${$(item).siblings('.title').text()} ${selectedTxt} `;
                    }
                });

            if (currVariation.description_title)
                descriptionProductBlock.find('.title').html(currVariation.description_title);
            else
                descriptionProductBlock.find('.title').html(`${descriptionProductBlock.find('.title').data('clearTitle')} ${domDescriptionTitle}`);

            descriptionProductBlock.find('#short-default-description').html(currVariation.description);
        }

        if (!reset && galleryProductBlock.length && Object.keys(currVariation.productFiles).length) {
            let mainSlider = galleryProductBlock.find('.mainSlider'),
                secondarySlider = galleryProductBlock.find('.secondarySlider'),
                mainSliderFiles = [],
                secondarySliderFiles = [];

            if (Object.keys(currVariation.files).length) {
                if (currVariation.files.medium.length)
                    mainSliderFiles = currVariation.files.medium;

                if (currVariation.files.small.length)
                    secondarySliderFiles = currVariation.files.small;
            } else {
                if (currVariation.productFiles.medium.length)
                    mainSliderFiles = currVariation.productFiles.medium;

                if (currVariation.productFiles.small.length)
                    secondarySliderFiles = currVariation.productFiles.small;
            }

            if (mainSlider.length && mainSliderFiles.length) {
                mainSlider.slick('removeSlide', null, null, true);

                mainSliderFiles.map(item => {
                    mainSlider.slick('slickAdd', `<div><div><div class="imgContainer"><img src="${item}" alt="${currVariation.slug}" title="${currVariation.name}"></div></div></div>`);
                });
            }

            if (secondarySlider.length && secondarySliderFiles.length) {
                secondarySlider.slick('removeSlide', null, null, true);

                secondarySliderFiles.map(item => {
                    secondarySlider.slick('slickAdd', `<div><div><div class="imgContainer"><img src="${item}" alt="${currVariation.slug}" title="${currVariation.name}"></div></div></div>`);
                });
            }
        }
    }

    function updateInputsAndSelects(that, allFormWraps) {

        if (that.prop('tagName') === 'INPUT') {
            if (that.siblings('label').hasClass('disabled')) {
                allFormWraps.removeClass('active').find('input[type="radio"]').prop('checked', false);
                allFormWraps.find('select option').removeClass('disabled').prop('selected', false);
                that.prop('checked', true).parents('.attribute-item-wrap').addClass('active');

                manipulateWithVariation(true);
            }
        } else if (that.prop('tagName') === 'SELECT') {
            let currVal = that.val();
            let disabledOptionValue = that.find(`option.disabled[value="${currVal}"]`);
            if (disabledOptionValue.length) {
                allFormWraps.removeClass('active').find('input[type="radio"]').prop('checked', false);
                allFormWraps.find('select option').removeClass('disabled').prop('selected', false);
                that.find(`option[value="${currVal}"]`).prop('selected', true).parents('.attribute-item-wrap').addClass('active');

                manipulateWithVariation(true);
            }
        }

        allFormWraps.find('label').removeClass('disabled');
        allFormWraps.find('select').find('option').removeClass('disabled').trigger('change.select2');
    }


    function findMatchingVariations(allAttributesArr, attributes) {
        let matching = [];

        for (let i = 0; i < allAttributesArr.length; i++) {
            let attribute = allAttributesArr[i];

            if (isMatch(attribute, attributes))
                matching.push(attribute);
        }
        return matching;
    }

    function isMatch(variation_attributes, attributes) {
        let match = true;

        for (let attrName in variation_attributes) {

            if (variation_attributes.hasOwnProperty(attrName)) {
                let val1 = variation_attributes[attrName];
                let val2 = parseInt(attributes[attrName]);

                if (val1 !== undefined && val2 !== undefined && !isNaN(val1) && !isNaN(val2) && val1 !== val2)
                    match = false;
            }
        }
        return match;
    }

    function getChosenAttributes(formAttributesFields) {
        let data = {};
        let count = 0;
        let chosen = 0;

        formAttributesFields.each((index, item) => {
            let attributeName = $(item).data('itemSlug'),
                attributeType = $(item).data('itemType'),
                attributesField = attributeType !== 'select' ? $(item).find('[name^=attributes]:checked') : $(item).find('[name^=attributes]'),
                value;

            value = attributesField.val() || '';

            if (value.length > 0)
                chosen++;

            count++;
            data[attributeName] = value;
        });

        return {
            'count': count,
            'chosenCount': chosen,
            'data': data
        }
    }

    function getCurrVariation() {
        let form = $('form.add-to-cart-form'),
            variationsStr = form.find('input[type=hidden][name=variations]').val();

        if (!variationsStr.length)
            return {};

        let allSelectedAttributesIds = [],
            allVariations = JSON.parse(variationsStr),
            variations = allVariations.variations,
            variationsArr = Object.keys(variations),
            serializeForm = form.serializeArray(),
            currVariation = {},
            allFormWraps = form.find('.attribute-item-wrap'),
            formAttributesFields = allFormWraps.closest('.attribute-item-container'),
            chosenAttributes = getChosenAttributes(formAttributesFields);

        if (serializeForm.length)
            serializeForm.map((item) => {
                if (item.name.startsWith('attributes'))
                    allSelectedAttributesIds.push(parseInt(item.value));
            });

        allSelectedAttributesIds = allSelectedAttributesIds.filter(arrUnique);

        let checkIfAllAttrIsCheeked = chosenAttributes.count === chosenAttributes.chosenCount;

        variationsArr.map((variationId) => {
            let item = variations[variationId],
                attributes = item.attributes;

            if (attributes instanceof Array) {
                attributes.map(attrs => {
                    if (checkIfAllAttrIsCheeked && arraysEqual(Object.values(attrs).sort(), allSelectedAttributesIds.sort())) {
                        currVariation = item.variation;
                        currVariation['id'] = parseInt(variationId);
                    }
                });
            } else {
                if (checkIfAllAttrIsCheeked && arraysEqual(Object.values(attributes).sort(), allSelectedAttributesIds.sort())) {
                    currVariation = item.variation;
                    currVariation['id'] = parseInt(variationId);
                }
            }

        });

        return currVariation;
    }

    window.updateProductQuantity = function (evt, that) {
        let operation = that.data('op');
        let isInput = $(evt.target).get(0).nodeName === 'INPUT';
        let quantity = 0;

        if (isInput) {

            if (evt.type === 'click')
                return false;

            let e = evt || window.event;
            let key = e.which || e.keyCode;
            let validKeys = (
                key >= 48 && key <= 57 // numbers
                || key >= 96 && key <= 105 // num pad
                || key === 8 // backspace
                || key === 46 // delete
            );

            if (!validKeys)
                that.val(!isNaN(parseInt(that.val())) ? parseInt(that.val()) : 1);

            if ((parseInt(that.val()) < 1 && !isNaN(parseInt(that.val()))))
                that.val(1);
            else if (that.val() !== '')
                that.val(parseInt(that.val()));

            quantity = parseInt(that.val());

        } else if (operation !== undefined) {
            let inputVal = parseInt(that.siblings('input').val());

            if (operation === 'diff')
                that.siblings('input').val(inputVal - 1 > 0 ? inputVal - 1 : 1);
            else if (operation === 'sum')
                that.siblings('input').val(inputVal + 1);

            quantity = parseInt(that.siblings('input').val());
        }

        if (isInput) {
            if (that.data('max-value') !== undefined && that.data('max-value') !== '' && that.data('max-value') < quantity) {
                that.val(that.data('max-value'));
                if (that.closest('.qty').hasClass('decreasedStock'))
                    quantity = parseInt(that.val());
            }
        } else {
            if (that.siblings('input').data('max-value') !== undefined && that.siblings('input').data('max-value') !== '' && that.siblings('input').data('max-value') < quantity)
                if (that.siblings('input').data('max-value') !== undefined && that.siblings('input').data('max-value') !== '' && that.siblings('input').data('max-value') < quantity) {
                    that.siblings('input').val(that.siblings('input').data('max-value'));
                    if (that.closest('.qty').hasClass('decreasedStock'))
                        quantity = parseInt(that.siblings('input').val());
                }
        }

        return quantity;
    };
})
(jQuery);

function arrUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function arrDiff(arr1, arr2) {
    return [...arr1].filter(x => !arr2.includes(x));
}

function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length)
        return false;
    for (let i = arr1.length; i--;) {
        if (arr1[i] !== arr2[i])
            return false;
    }

    return true;
}