(function ($) {

    window.submitForm = function (parentThat) {

        if (!(parentThat instanceof jQuery))
            parentThat = $(parentThat);

        let formId = parentThat.data('form-id');
        let form = $('#' + formId);

        if (form.length < 1) {
            return;
        }

        let submitBtn = parentThat;

        form.submit(function (event) {
            event.preventDefault();
        });

        let serializedForm = form.find("select, textarea, input").serializeArray();

        form.find('input, textarea, select').removeClass('error');
        form.find('div.errorMsg').remove();

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            beforeSend: function () {
                submitBtn.addClass('loading');
            },
            data: serializedForm
        })
            .success(function (response) {
                window.successErrorAjaxBtn(submitBtn, response.status);

                if (response.msg && !response.validator)
                    toastr[response.msg.type](response.msg.e);

                if (response.status) {
                    if (response.redirect)
                        window.location.href = response.redirect;

                    if (!response.preventClearForm)
                        form.find('input:not(input[type=hidden]):not([name="rating[]"]), textarea').val('');

                    form.find('input, textarea, select').removeClass('error');

                    if (response.reset_password_modal){
                        $('#reset-message').html(response.reset_message);
                        $('#reset-password-call').click()
                    }

                } else {

                    if (response.validator && response.msg) {
                        $.each(response.msg.e, function (ObjNames, ObjValues) {

                            let field = form.find("[name='" + ObjNames + "']");

                            if (field.length > 0) {
                                field.addClass('error');
                                if (field.prop("tagName") === 'SELECT') {
                                    field.closest('.selectContainer').append(`<div class="errorMsg ${ObjNames}">${ObjValues}</div>`);
                                } else {
                                    if (field.attr('type') === 'checkbox') {
                                        field.closest('.field-wrap').append(`<div class="errorMsg ${ObjNames}">${ObjValues}</div>`);
                                    } else {
                                        field.after(`<div class="errorMsg ${ObjNames}">${ObjValues}</div>`);
                                    }
                                }
                            }
                        });
                    }

                }

            })
            .fail(function (response) {
                window.successErrorAjaxBtn(submitBtn, false);

                let jsonResponse = response.responseJSON;

                if (jsonResponse.msg)
                    toastr[jsonResponse.msg.type](jsonResponse.msg.e);
            });
    };


    window.loadMore = function (parentThat) {
        if (!(parentThat instanceof jQuery))
            parentThat = $(parentThat);

        let loadMoreContent = parentThat.siblings('.load-more-content');
        if (!loadMoreContent.length)
            loadMoreContent = parentThat.parent().siblings('.load-more-content');

        let url = parentThat.data('url');
        let currPage = parentThat.data('currentPage');
        let lastPage = parentThat.data('lastPage');
        let itemId = parentThat.data('itemId') !== undefined ? parentThat.data('itemId') : '';

        if (!loadMoreContent.length || !url || !currPage || !lastPage)
            return false;

        let data = {
            currPage: currPage,
            lastPage: lastPage,
            itemId: itemId
        };

        if (currPage > 0 && currPage < lastPage)
            $.ajax({
                method: "POST",
                url: url,
                beforeSend: function () {
                    parentThat.addClass('loading');
                },
                data: data
            })
                .success(function (response) {
                    parentThat.removeClass('loading');

                    if (response.status) {
                        if (currPage === lastPage - 1)
                            parentThat.addClass('hidden');

                        parentThat.data('currentPage', response.currentPage);
                        parentThat.data('lastPage', response.lastPage);

                        if (response.view)
                            loadMoreContent.append(response.view);
                    }

                    initLazyLoader();

                })
                .fail(function (response) {
                    parentThat.removeClass('loading');
                });
    };

    window.successErrorAjaxBtn = function (btn, status, timeout = 1000) {
        if (timeout === undefined || !$.isNumeric(timeout))
            timeout = 1000;

        if (status)
            btn.addClass('added');
        else
            btn.addClass('error');

        setTimeout(function () {
            btn.removeClass('loading').removeClass('added').removeClass('error');
        }, timeout);
    };

    window.magicToken = function () {
        $.get(`${window.location.origin}/magic-token`)
            .done(function (response) {
                if (response.status) {
                    $.ajaxSetup({
                        headers: {
                            'x-csrf-token': response.token
                        }
                    });
                }
            });
    };

    window.removeURLParameter = function (url, parameter) {

        let urlparts = url.split('?');
        if (urlparts.length >= 2) {

            let prefix = encodeURIComponent(parameter) + '=';
            let pars = urlparts[1].split(/[&;]/g);

            for (let i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    };

    $.fn.isInViewport = function () {
        let elementTop = $(this).offset().top,
            elementBottom = elementTop + $(this).outerHeight(),
            viewportTop = $(window).scrollTop(),
            viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    window.initLazyLoader = function () {
        new LazyLoad({
            elements_selector: ".lazy",
           // load_delay: 300 //adjust according to use case
        });
    };
})

(jQuery);