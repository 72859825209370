(function ($) {

    $(document).ready(function () {

        /*** open/close cart container on click ***/
        $(".header .profileContainerOnHeader .profileBtn").on("click", function (e) {
            $(".header .profileLinksContainer").toggle();
            $(".header .cartResultContainer").hide();
            $(".header .dropdownMenu").removeClass("active");
            e.stopPropagation();
        });

        /*** close cart container ***/
        $(document).on("click", function (e) {
            if (!$(".header .profileContainerOnHeader .profileLinksContainer").has(e.target).length && !$(".header .profileContainerOnHeader .profileLinksContainer").is(e.target)) {
                $(".header .profileContainerOnHeader .profileLinksContainer").hide();
            }
        });

        // $(".profileBtn").click(function (e) {
        //
        //     $(".profileContainerOnHeader ").toggleClass("color-active");
        // });
    })

})(jQuery);