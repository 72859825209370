(function($) {
    

	$(document).ready(function() {

        function initMenu() {
            if( $(window).width() > 1024 ){
                /*** subcategoryContainer open on click ****/
                $(".nav .categoryContainer .category").click(function(e){
                    $(".subcategoryContainer").not($(this).siblings(".subcategoryContainer")).removeClass("active");
                    $(this).siblings(".subcategoryContainer").toggleClass("active");
                    e.stopPropagation();
                })
            }
            if( $(window).width() <= 1024 ){ 
                /*** subcategoryContainer open on click ****/
                $(".nav .categoryContainer .category").click(function(e){
                    $(".subcategoryContainer").not($(this).siblings(".subcategoryContainer")).removeClass("active");
                    $(this).siblings(".subcategoryContainer").toggleClass("active");
                    e.stopPropagation();
                })
            }
        }

        initMenu();

        $( window ).resize(function() {
            initMenu();
        });

       
        

        $('.nav .has-subcategories .category').click(function (e) {
            $('#categories_name').html('')
        })
            
        /*** subcategoryContainer close ****/
        $(document).on("click", function(e) {
            if (!$(".nav .categoryContainer .subcategoryContainer").has(e.target).length && !$(".nav .categoryContainer .subcategoryContainer").is(e.target)) {
              $(".nav .categoryContainer .subcategoryContainer").removeClass("active");
            }
        });
        
        /*** linksContainer open on hover/click ****/
        if( $(window).width() > 768 ){
            $(".nav .categoryContainer .subcategoryContainer .title").hover(function(){
                $(this).parent(".subcategory").parent(".subcategoryContainer").find(".title").not(this).removeClass("active");
                $(this).addClass("active");
                $(this).parent(".subcategory").parent(".subcategoryContainer").find(".linksContainer").not($(this).siblings(".linksContainer")).removeClass("active");
                $(this).siblings(".linksContainer").addClass("active");
            })
        }
        if( $(window).width() <= 768 ){
            $(".nav .categoryContainer .subcategoryContainer .subcategory .title, .nav .categoryContainer .subcategoryContainer .subcategory .linksContainer").removeClass("active");
            $(".nav .categoryContainer .subcategoryContainer .title").click(function(){
                $(this).parent(".subcategory").parent(".subcategoryContainer").find(".title").not(this).removeClass("active");
                $(this).addClass("active");
                $(this).parent(".subcategory").parent(".subcategoryContainer").find(".linksContainer").not($(this).siblings(".linksContainer")).removeClass("active");
                $(this).siblings(".linksContainer").addClass("active");
            })
        }

        /*** open mobile menu on click***/
        $(".mobileMenu .openMobileMenuBtn").click(function(){
            $(".nav").addClass("active");
            $(".mobileMenuMaskForClose").addClass("active");
            $('#categories_name').html('<div class="name-category">Categorii</div>\n')
        })

        /*** close mobile menu on click***/
        $(".nav .closeMenuBtn, .mobileMenuMaskForClose").click(function(){
            $(".nav, .mobileMenuMaskForClose").removeClass("active");
            $(".nav .subcategory .linksContainer").removeClass("active");
        })
        
        /*** go back one step on mobile menu categories***/
        $(".nav .categoryContainer .subcategoryContainer .backOneStep.toMenu").click(function(){
            $(this).parents(".subcategoryContainer ").removeClass("active");
            $('#categories_name').html('<div class="name-category">Categorii</div>\n')
        })
        $(".nav .categoryContainer .subcategoryContainer .subcategory .linksContainer .backOneStep.toSubcategory").click(function(){
            $(this).parents(".linksContainer ").removeClass("active");            
        })

    })

})(jQuery);