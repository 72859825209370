(function ($) {

  $('#play-video').click(function() {
    $('#video').trigger('play');
    $(this).hide();
  });

  $('#video').click(function(){
    this.paused ? this.play() : this.pause();
    $('#play-video').hide();
  });

})(jQuery);