(function ($) {

    $(document).ready(function () {

        /*** open full search container with mask ***/
        // $(".header .searchContainerOnHeader input").focus(function(){
        //     $(".header .searchContainerOnHeader .searchMask").show();
        //     $(".header .searchContainerOnHeader form").addClass("active");
        // });

        /*** close search container ***/
        $(".header .searchContainerOnHeader .searchMask").on('click', function () {
            let searchContainerOnHeader = $(this).closest('.searchContainerOnHeader');

            $(this).hide();
            searchContainerOnHeader.removeClass("active");
            searchContainerOnHeader.find('form').removeClass("active");
            $('#search-icon').addClass("disable-header-button");
            $('#search-icon').removeClass("active-header-button");
            $('#searchResult').hide();

            $('#search-form input').val('');
        });

        /*** open full search container with mask on mobile ***/
        $(".header .searchBtnOnMobile").on('click', function () {
            let searchContainerOnHeader = $('.header .searchContainerOnHeader');

            searchContainerOnHeader.find('.searchMask').show();
            searchContainerOnHeader.addClass("active");

        });

        /*** open search results ***/
            // $(".header .searchContainerOnHeader input").keyup(function () {
            //     if ($(this).val().length >= 1) {
            //         $(".header .searchResultMainContainer").slideDown();
            //     } else {
            //         $(".header .searchResultMainContainer").slideUp(200);
            //     }
            // });


            // Search form

        let searchSetTimeout;

        $('#search-form input[type="text"]').on('keyup', function (evt) {

            let form = $(this).parent('form'),
                e = evt || window.event,
                key = e.which || e.keyCode,
                submitBtn = form.find('button[type="submit"]'),
                serializedForm = form.serializeArray(),
                searchResult = $("#searchResult"),
                resetSearchInputBtn = form.find('button[type=reset]');

            let validKeys = (
                key >= 48 && key <= 57 // numbers
                || key >= 65 && key <= 90 // letters
                || key >= 96 && key <= 105 // num pad
                || key >= 186 && key <= 222 // symbols
                || key === 8 // backspace
                || key === 46 // delete
                || key === 13 // enter
            );

            if ($(this).val().length)
                resetSearchInputBtn.fadeIn(300);
            else
                resetSearchInputBtn.hide();

            if (!validKeys || $(this).val().length < 3)
                return false;

            submitBtn.prop('disabled', false);

            searchResult.slideUp(200, function () {
                $(this).html('');
            });

            if (searchSetTimeout !== undefined)
                clearTimeout(searchSetTimeout);

            searchSetTimeout = setTimeout(function () {
                $.ajax({
                    method: "POST",
                    url: form.attr('action'),
                    beforeSend: function () {
                        form.addClass('loading');
                    },
                    data: serializedForm
                })
                    .success(function (response) {
                        form.removeClass('loading');

                        submitBtn.prop('disabled', !response.status);

                        let isActive = $('#search-form').hasClass('active')

                        if (response.status) {
                            if (response.view.length && isActive) {
                                searchResult.html(response.view);
                                searchResult.slideDown();
                            }
                        } else
                            searchResult.slideUp(200);

                    })
                    .fail(function (response) {
                        form.removeClass('loading');

                        searchResult.slideUp(200);
                    });

            }, 1000);

        }).on('focus', function () {
            let searchContainerOnHeader = $('.header .searchContainerOnHeader');
            searchContainerOnHeader.find('.searchMask').show();
            searchContainerOnHeader.find('#search-form').addClass("active");
            $('#search-icon').addClass("active-header-button");
            $('#search-icon').removeClass("disable-header-button");
        });

        $('.disable-header-button').on('click', function (e) {
            if ($('#search-icon').hasClass("disable-header-button"))
                e.preventDefault();

            let searchContainerOnHeader = $('.header .searchContainerOnHeader');
            searchContainerOnHeader.find('.searchMask').show();
            searchContainerOnHeader.find('#search-form').addClass("active");
            searchContainerOnHeader.find('input[type=text]').focus();
            $('#search-icon').addClass("active-header-button");
            $('#search-icon').removeClass("disable-header-button");
        });

        $('#search-form button[type="reset"]').on('click', function () {
            $(this).hide();
        });

        // Search form
    });

})(jQuery);