(function ($) {

    $(document).ready(function () {

        let companySlider = $('.companySlider');

        $('.companySlider').slick({
            // rtl: true
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true

        });
    })

})(jQuery);