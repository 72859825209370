(function ($) {

    $(document).ready(function () {
        let viewTypeItems = $(".view-type .item");
        if (viewTypeItems.length) {
            viewTypeItems.on('click', function () {
                let productsListBlock = $(".products-list");

                if (!$(this).hasClass("active")) {
                    viewTypeItems.removeClass("active");
                    $(this).addClass("active");

                    productsListBlock.toggleClass("horizontal", $(this).data('type') === 'horizontal');

                    $.ajax({
                        method: "POST",
                        url: `${window.location.origin}/${window.globalLang}/catalog-list-view`,
                        data: {
                            orientation: $(this).data('type')
                        }
                    })
                        .success(function (response) {
                            if (response.status) {
                                if (response.orientation === "horizontal")
                                    productsListBlock.addClass("horizontal");
                                else
                                    productsListBlock.removeClass("horizontal");
                            } else
                                productsListBlock.removeClass("horizontal");

                        })
                        .fail(function () {
                            productsListBlock.removeClass('horizontal');
                        });
                }

            });
        }

    })

})(jQuery);