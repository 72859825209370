(function ($) {

  $(document).ready(function () {
      let productArDiscountBlock = $('.our-instagram-slider');

      if (productArDiscountBlock.length) {
          productArDiscountBlock.slick({
              infinite: true,
              dots: true,
              arrows: false,
              slidesToShow: 6,
              slidesToScroll: 6,
              responsive: [
                  {
                      breakpoint: 1201,
                      settings: {
                          slidesToShow: 5,
                          slidesToScroll: 5,
                      }
                  },
                  {
                      breakpoint: 1025,
                      settings: {
                          slidesToShow: 4,
                          slidesToScroll: 4
                      }
                  },
                  {
                      breakpoint: 730,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                      }
                  },
                  {
                      breakpoint: 560,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                      }
                  },
                  {
                      breakpoint: 380,
                      settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                      }
                  }
              ]
          });
      }



  })

})(jQuery);