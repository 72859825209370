(function ($) {

    $(document).ready(function () {

        /*** Open change password block ***/
        $(".changePasswordBtn").on("change", function () {
            $('#passwords-block').toggleClass("active");
        });

    });

})
(jQuery);