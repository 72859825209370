(function ($) {
    //on load
    $(window).on('load', function () {

    });

    //on ready
    $(document).ready(function () {

        //stiky menu

        var productBlock = $('.product-add-to-cart-info-wrap');

        if(productBlock.length) {

            var stickyNav = function () {
                var stickyNavTop = $('.product-add-to-cart-info-wrap').offset().top;
                var scrollTop = $(window).scrollTop();
                if (scrollTop > stickyNavTop) {
                    $('.catalogItem-row1').addClass('sticky');
                } else {
                    $('.catalogItem-row1').removeClass('sticky');
                }
            };

            $(window).scroll(function () {
                stickyNav();
            });

        }

        var anchors = $('.anchorsNav');

        var sticky = function () {
            var stickyNavTop = $('.anchorsNav').offset().top;
            var scrollTop = $(window).scrollTop();

            $('.catalogItem-row2').css('top',  $('.catalogItem-row1').css("height") );

            if (scrollTop > stickyNavTop) {
                $('.catalogItem-row2').addClass('sticky');
            } else {
                $('.catalogItem-row2').removeClass('sticky');
            }
        };

        if(anchors.length) {

            $(window).scroll(function () {
                sticky();
            });

        }


        // 

        
        //b-one click stiky menu

        $("#buy-in-one-click-stiky-menu").click(function (e) {
            e.preventDefault();
            $(".show-in-one-click-block-stiky-menu").addClass("show");
            $("#buy-in-one-click-stiky-menu").addClass("active");
            sticky();
        });

        $("#close-buy-one-click-stiky-menu").click(function (e) {
            $(".show-in-one-click-block-stiky-menu").removeClass("show");
            $("#buy-in-one-click-stiky-menu").removeClass("active");
            sticky();
        });




        $('.getModal').on('click', function (e) {
            e.preventDefault();
            let target_modal = $(this).attr('href');
            if (!target_modal) {
                target_modal = $(this).data('modal');
            }
            $.arcticmodal('close');

            $(target_modal).arcticmodal({
                 //closeOnOverlayClick: false,
                beforeOpen: function () {
                    $(target_modal).addClass('openEffect').addClass('isOpen');
                    $(target_modal).removeClass('closeEffect');
                    $("body").addClass("modal-open");
                },
                beforeClose: function () {
                    $(target_modal).removeClass('openEffect');
                    $(target_modal).addClass('closeEffect');
                    $("body").removeClass("modal-open");


                    setTimeout(function () {
                        $(target_modal).removeClass('isOpen');
                    }, 600);
                }
            });
        });

        $(".modal").on("click", function (e) {
             // e.stopPropagation();
        })

        //scroll top
        var top_show = 250;
        var delay = 1000;
        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > top_show) $('#top').fadeIn();
                else $('#top').fadeOut();
            });
            $('#top').click(function () {
                $('body, html').animate({
                    scrollTop: 0
                }, delay);
            });
        });


        //scroll to anchors
        $('.scrolling').click(function () {
            let target = $(this).attr('href').match('#');
            target = target.input.slice(target['index']);
            $('html, body').animate({scrollTop: $(target).offset().top}, 500);
            return false;
        });

        // hide placeholder on focus and return it on blur
        $('input, textarea').focus(function () {
            $(this).data('placeholder', $(this).attr('placeholder'));
            $(this).attr('placeholder', '');
        });
        $('input, textarea').blur(function () {
            $(this).attr('placeholder', $(this).data('placeholder'));
        });

        /************************ Personal scripts ************************/

        /*** Select2 ***/
        $('select.select2').select2({
            templateResult: select2FormatState,
            width: '100%'
        });
        $('select.select2.no-search').select2({
            templateResult: select2FormatState,
            minimumResultsForSearch: -1,
            width: '100%'
        });

        $('select.select2.ajax').select2({
            width: '100%',
            templateResult: select2FormatState,
            ajax: {
                url: function () {
                    return $(this).data('url');
                },
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        page: params.page,
                        q: params.term
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;

                    return {
                        results: data.status ? data.items : [],
                        pagination: {
                            more: data.status && (params.page * 10) < data.total
                        }
                    };
                },
                cache: true
            },
            minimumInputLength: 1,
        });

//    Select countries and manipulate regions

        $('select.select2.ajax.countries-list').on('select2:select', function () {
            let _this = $(this),
                regionsSelect = $('select.select2.ajax.regions-list'),
                isMultiple = $(this).attr('multiple') !== undefined,
                currVal = isMultiple ? _this.val().join() : _this.val(),
                getParameterName = isMultiple ? `countriesIds` : `countryId`,
                getParameter = isMultiple ? `?countriesIds[${currVal}]` : `?countryId=${currVal}`;

            if (!regionsSelect.length)
                return false;

            let regionsUrl = regionsSelect.data('url');
            let newUrl = window.removeURLParameter(regionsUrl, getParameterName) + getParameter;
            regionsSelect.data('url', newUrl);
        });

        //    Select countries and manipulate regions

        initLazyLoader();




    }); //END on ready

    window.select2FormatState = function (state) {
        let element = $(state.element);

        let response;
        response = $(`<span class="${(element.length && element.attr('class') ? element.attr('class') : '')}">${state.text}</span>`);

        return response;
    }

  

    //star for reviews

    $('.stars .single_star').on('click', function () {
        let a = $('.single_star')
        let b = this;
        $('input', a).prop('checked', false)
        setTimeout(function () {
            a.each(function (item) {
                if ($('input', this).val() < $('input', b).val()) {
                    $('input', this).prop('checked', true)
                }
            })
        }, 0)
        $('input', b).prop('checked', true)
        $('.number_review').text($('input', b).val())
    })

    //by-one-click

    $("#buy-in-one-click").click(function (e) {
        $("#buy-in-one-click").addClass("active");

        e.preventDefault();
        $(".show-in-one-click-block").addClass("show");
    });

    $("#close-in-one-click").click(function (e) {
        $(".show-in-one-click-block").removeClass("show");
        $("#buy-in-one-click").removeClass("active");
    });

// button active disabled //errorMsg
    $('#error-input').on('keyup', function () {

        if ( $(this).val().length > 3) {
            $('#error-button').attr('disabled', false)
        } else {
            $('#error-button').attr('disabled', true)
        }

    });

// button active disabled //by one click
    $('#one-click-input').on('keyup', function () {

        if ( $(this).val().length > 3) {
            $('#one-click-send').attr('disabled', false)
        } else {
            $('#one-click-send').attr('disabled', true)
        }

    });

 //button active disabled buy one click stiky menu

 $('#one-click-input-stiky-menu').on('keyup', function () {

    if ( $(this).val().length > 3) {
        $('#one-click-send-stiky-menu').attr('disabled', false)
    } else {
        $('#one-click-send-stiky-menu').attr('disabled', true)
    }

});

 $('#nextReviews').on('click', function () {
     let page =$('#current_page').val();
     $.ajax({
         method: "POST",
         url: window.location.origin + '/'+ window.globalLang + '/load-more-review',

         beforeSend: function () {
             $('#btnForNextRevies').addClass('loading');
         },
         data: {
             page: page,
             itemId: $('.product_id_for_reviews').val()
         }
     })
         .success(function (response) {
             console.log(page);
             if (response.viewHtml != null){
                 $('.reviewsContainer .list-review').append(response.viewHtml);
                 $('#current_page').val(parseInt(page) + 1);
                 if (!response.showMore){
                     $('#nextReviews').css("display", "none");
                 }
             }
         })
 });

    $('#one-click-send').on('click', function (e) {
        e.preventDefault();

        let field = $('#one-click-input');
        $.ajax({
            method: "POST",
            url: window.location.origin + '/'+ window.globalLang + '/addToCart',

            beforeSend: function () {
                // $('#one-click-send').addClass('loading');
            },
            data: {
                action: 'buyInOneClick',
                item_id: $('#itemIdForCart').val(),
                client_phone: field.val()
            }
        })
            .success(function (response) {
                if (response.status) {
                    field.removeClass('error');
                    field.val('');
                    $('label.errorMsg').remove();
                    if (response.msg)
                        toastr[response.msg.type](response.msg.e);
                } else {
                    field.addClass('error');
                    field.after(`<label class="errorMsg client_phone" for="client_phone">${response.msg.e.client_phone}</label>`)
                }
            })
    });

    $('#one-click-send-stiky-menu').on('click', function (e) {
        // console.log(window.location.origin + '/'+ window.globalLang + '/addToCart');
        e.preventDefault();
        let field = $('#one-click-input-stiky-menu');
        $.ajax({
            method: "POST",
            url: window.location.origin + '/'+ window.globalLang + '/addToCart',

            beforeSend: function () {
                // $('#one-click-send').addClass('loading');
            },
            data: {
                action: 'buyInOneClick',
                item_id: $('#itemIdForStikyCart').val(),
                client_phone: field.val()
            }
        })
            .success(function (response) {
                if (response.status) {
                    field.removeClass('error');
                    $('label.errorMsg').remove();
                    field.val('');
                    if (response.msg)
                        toastr[response.msg.type](response.msg.e);
                } else {
                    field.addClass('error');
                    field.after(`<label class="errorMsg client_phone" for="client_phone">${response.msg.e.client_phone}</label>`)
                }
            })
    });

    $('#buy-one-click-cart-tabs').on('click', function (e) {
        e.preventDefault();
        let field = $('#by-one-click-input-cart-tabs');
        $.ajax({
            method: "POST",
            url: window.location.origin + '/'+ window.globalLang + '/buyInOneClick',

            beforeSend: function () {
                // $('#one-click-send').addClass('loading');
            },
            data: {
                action: 'buyInOneClick',
                item_id: $('#by-one-click-item-id-tabs').val(),
                client_phone: field.val()
            }
        })
            .success(function (response) {
                if (response.status) {
                    field.removeClass('error');
                    $('label.errorMsg').remove();
                    field.val('');
                    if (response.msg)
                        toastr[response.msg.type](response.msg.e);
                } else {
                    field.addClass('error');
                    field.after(`<label class="errorMsg client_phone" for="client_phone">${response.msg.e.client_phone}</label>`)
                }
            })
    });

    //cookies

    $(".exit-button").click(function (e) {
        $.ajax({
            method: "POST",
            url: window.location.origin + '/'+ window.globalLang + '/cookieSet',

            beforeSend: function () {
                $(".cookies-container").addClass("hide");
            },
            data: {
                accept_cookie: 'true',
            }
        })
    });

    $(document).on('click', ".add-to-cart", function (e) {
        e.preventDefault();

        let itemId = $(this).attr('data-item-id')

        $.ajax({
            method: "POST",
            url: window.location.origin + '/'+ window.globalLang + '/addToCart',

            beforeSend: function () {
                $(this).addClass('loading');
            },
            data: {
                 item_id: itemId,
                action: 'addToCart'
            }
        })
            .success(function (response) {
                if (response.status){
                    $(".add-to-cart").addClass('success');
                    $("#cart-count").html(response.count);
                    $("#cart-count").addClass('show');
                    if (response.thumbnail && $('#cart-preview').length)
                        $('#cart-preview').html(response.thumbnail);
                    if (response.msg)
                        toastr[response.msg.type](response.msg.e);

                } else {
                    $(".add-to-cart").addClass('error')
                }
            })
    });

})(jQuery);