(function ($) {
    window.globalLang = $('html').attr('lang');

    $(document).on('ready', function () {

        setInterval(magicToken, 1000 * 60 * 120);

        $.ajaxSetup({
            headers: {
                'x-csrf-token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $(document).ajaxError(function (event, xhr) {

            if (xhr.status === 419) {
                magicToken();
            }

        });

        toastr.options = {
            "closeButton": true,
            "progressBar": true,
            "positionClass": "toast-top-right",
            "showDuration": "2000",
            "hideDuration": "2000",
            "timeOut": "3000",
            "extendedTimeOut": "1000",
            // "timeOut": 0,
            // "extendedTimeOut": 0
        };

        /*** Submit form ***/

        $(document).on('click', '.submit-form-btn', function (e) {
            e.preventDefault();
            submitForm(this);
        });

        /*** Submit form ***/

        /***Load more items***/

        $('.load-more-btn').on('click', function (e) {
            e.preventDefault();
            loadMore(this);
        });

        /***Load more items***/

        /***Pagination form***/

        $(document).on('submit', '#pagination-form', function (e) {
            e.preventDefault();

            let _this = $(this),
                url = _this.attr('action'),
                page = parseInt(_this.find('input[type=text][name=page]').val()) || 1,
                lastPage = _this.data('lastPage');

            if (!url || page > parseInt(lastPage) || page < 1)
                return false;

            window.location.href = updateQueryStringParam('page', page);
        });

        /***Pagination form***/

    });

    function updateQueryStringParam(key, value) {

        let baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
            urlQueryString = document.location.search,
            newParam = key + '=' + value,
            params = '?' + newParam;

        // If the "search" string exists, then build params from it
        if (urlQueryString) {

            let updateRegex = new RegExp('([\?&])' + key + '[^&]*');
            let removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

            if (typeof value === 'undefined' || value == null || value === '') { // Remove param if value is empty
                params = urlQueryString.replace(removeRegex, "$1");
                params = params.replace(/[&;]$/, "");

            } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
                params = urlQueryString.replace(updateRegex, "$1" + newParam);

            } else { // Otherwise, add it to end of query string
                params = urlQueryString + '&' + newParam;
            }
        }

        // no parameter was set so we don't need the question mark
        params = params === '?' ? '' : params;

        return baseUrl + params;
    }
})
(jQuery);