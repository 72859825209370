(function ($) {

    $(document).ready(function () {

        let similarProductSlider = $('.similarProductSlider');

        if (similarProductSlider.length)
            similarProductSlider.slick({
                infinite: true,
                dots: true,
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                        breakpoint: 1201,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint:760,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    // {
                    //     breakpoint: 480,
                    //     settings: {
                    //         slidesToShow: 1,
                    //         slidesToScroll: 1
                    //     }
                    // }
                ]
            });
    })

})(jQuery);