//LIBS

//=include ./libs/jquery-1.11.1.min.js
//=include ./libs/jquery.arcticmodal-0.3.min.js

//=include ./libs/svgxuse.min.js
//=include ./libs/jquery.fancybox.min.js
//=include ./libs/slick.min.js
//=include ./libs/nouislider.min.js
//=include ./libs/select2.min.js
//=include ./libs/toastr.js
//=include ./libs/sharer.js
//=include ./libs/lazyload.min.js
//=include ./libs/starrr.js


//=include ./functions.js
//=include ./defaults.js


//COMPONENTS

//=include ./components/header/headerSearch.js
//=include ./components/header/headerCart.js
//=include ./components/header/headerProfile.js
//=include ./components/header/headerDropdown.js

//=include components/nav/nav.js

//=include ./components/main/mainSlider.js
//=include ./components/main/mainPopularProductsCategories.js
//=include ./components/main/PopularGoods.js
//=include ./components/main/BestProduct.js
//=include ./components/main/OurInstagram.js
//=include ./components/main/aboutUs.js


//CATALOG

//=include ./components/catalog/catalogFilterList.js
//=include ./components/catalog/catalogSortList.js
//=include ./components/catalog/productCard.js

// PRODUCT PAGE

//=include ./components/productPage/addToCart.js
//=include ./components/productPage/mainDetails.js
//=include ./components/productPage/productDescription.js
//=include ./components/productPage/similarProductsSlider.js
//=include ./components/productPage/withThisProductItBuySlider.js
//=include ./components/productPage/viewedProductsSlider.js

//CART

//=include ./components/cart/cart.js
//=include ./components/cart/cartTabs.js

//AUTH

//=include ./components/auth/dashboard/dashboard.js
//=include ./components/auth/dashboard/dashboardSetting.js
//=include ./components/auth/dashboard/dashboardOrderHistory.js

//SEARCH

//=include ./components/search/search.js

//About Us
//=include ./components/aboutUs/aboutUs.js


//=include ./scripts.js

//REVIEWS
//=include ./components/reviews/reviews.js