(function ($) {

    $(document).ready(function () {

        /*** Open tabs ***/
        $(".cartTabs .navTabs .navItem").on("click", function () {
            let tabName = $(this).attr("data-tab"),
                cartTotalPriceBlock = $('.cart-total-price');

            $(this).siblings(".navItem").removeClass("active");
            $(this).addClass("active");
            $(this).parent().siblings().children().removeClass("active");
            $(document).find(".tabPane[data-tab=" + tabName + "]").addClass("active");

            if (cartTotalPriceBlock.length)
                if ($(this).data('tab') === 'pickUp') {
                    cartTotalPriceBlock.html(cartTotalPriceBlock.data('no-shipping-amount'));
                } else if ($(this).data('tab') === 'delivery') {
                    cartTotalPriceBlock.html(cartTotalPriceBlock.data('shipping-amount'));
                }
        });

        /*** Open login form ***/
        $(".cartTabs .tabContent .tabPane .showLoginFormBtn").on("click", function () {
            $(".cartTabs .tabContent .tabPane .loginForm").addClass("active");
            $(this).hide();
        });

        /*** Open login form ***/
        $(".cartTabs .tabContent .tabPane .registerForm .loginBtn").on("click", function () {
            $(".cartTabs .tabContent .tabPane .loginForm").addClass("active");
            $(".cartTabs .tabContent .tabPane .registerForm").removeClass("active");
        });

        /*** Open register form ***/
        $(".cartTabs .tabContent .tabPane .loginForm .registerBtn").on("click", function () {
            $(".cartTabs .tabContent .tabPane .registerForm").addClass("active");
            $(".cartTabs .tabContent .tabPane .loginForm").removeClass("active");
        });

        /*** Open comment area ***/
        $(" .addCommentBtn").on("click", function () {
            $(this).siblings().stop().slideToggle(300);
        });
        $(".addCommentBtn").click(function (e) {

            $(this).toggleClass("show-hiden");// + -
        });

        $('#client-country').on('select2:select', function () {
            let clientRegionSelect = $('#client-region');
            if (clientRegionSelect.length) {
                let clientRegionSelectUrl = clientRegionSelect.data('url'),
                    newUrl = `${window.removeURLParameter(clientRegionSelectUrl, 'countryId')}?countryId=${$(this).val()}`;

                clientRegionSelect.data('url', newUrl);
                clientRegionSelect.val('').trigger('change');
            }

            window.getShippingMethods();
        });

        $('#client-region').on('select2:select', function () {
            window.getShippingMethods();
        });

        $(document).on('select2:select', '#pickup-countries', function () {
            getPickupRegionsStores($(this));
        });

        $(document).on('select2:select', '#pickup-regions', function () {
            getPickupRegionsStores($('#pickup-countries'), $(this));
        });

        $(document).on('change', '.deliveryMethod', function () {
            let _this = $(this),
                shippingMethodId = _this.find('input[type=radio][name=shipping_method]:checked').val(),
                deliveryBlock = $('.cartPage .deliveryBlock'),
                shippingZoneId = $('#delivery-methods').data('shippingZoneId'),
                cartTotalPriceBlock = $('.cart-total-price');

            $.ajax({
                method: 'POST',
                url: `${window.location.origin}/${window.globalLang}/getShippingMethod`,
                beforeSend: function () {
                    deliveryBlock.addClass('loading');
                },
                data: {
                    shippingMethodId: shippingMethodId,
                    shippingZoneId: shippingZoneId
                }
            }).success(function (response) {
                deliveryBlock.removeClass('loading');

                if (response.msg && !response.validator)
                    toastr[response.msg.type](response.msg.e);

                if (response.status) {
                    if (response.cartTotal && response.cartTotalStr) {
                        cartTotalPriceBlock.html(response.cartTotalStr);
                        cartTotalPriceBlock.data('no-shipping-amount', response.cartSubtotalWithCouponStr);
                        cartTotalPriceBlock.data('shipping-amount', response.cartTotalStr);
                    }
                }
            }).fail(function (response) {
                deliveryBlock.removeClass('loading');
                let jsonResponse = response.responseJSON;

                if (jsonResponse.msg)
                    toastr[jsonResponse.msg.type](jsonResponse.msg.e);
            });
        });

    });

    window.getShippingMethods = function () {
        let deliveryMethodsBlock = $('#delivery-methods'),
            shippingErrorsBlock = $('#shipping-errors'),
            clientCountrySelect = $('#client-country'),
            clientRegionSelect = $('#client-region'),
            deliveryBlock = $('.cartPage .deliveryBlock'),
            cartTotalPriceBlock = $('.cart-total-price');

        $.ajax({
            method: 'POST',
            url: `${window.location.origin}/${window.globalLang}/getShippingMethods`,
            beforeSend: function () {
                deliveryBlock.addClass('loading');
            },
            data: {
                countryId: clientCountrySelect.val(),
                regionId: clientRegionSelect.val()
            },
        }).success(function (response) {
            deliveryBlock.removeClass('loading');

            if (response.msg && !response.validator)
                toastr[response.msg.type](response.msg.e);

            if (response.status) {
                if (deliveryMethodsBlock.length) {
                    deliveryMethodsBlock.data('shippingZoneId', response.shippingZoneId);
                    deliveryMethodsBlock.html(response.shippingMethodsView);

                    if (response.cartTotal && response.cartTotalStr && cartTotalPriceBlock.length) {
                        cartTotalPriceBlock.html(response.cartTotalStr);
                        cartTotalPriceBlock.data('no-shipping-amount', response.cartSubtotalWithCouponStr);
                        cartTotalPriceBlock.data('shipping-amount', response.cartTotalStr);
                    }
                }

                if (shippingErrorsBlock.length)
                    shippingErrorsBlock.html(response.errorsView);
            }
        }).fail(function (response) {
            deliveryBlock.removeClass('loading');
            let jsonResponse = response.responseJSON;

            if (jsonResponse.msg)
                toastr[jsonResponse.msg.type](jsonResponse.msg.e);
        });
    };

    function getPickupRegionsStores(country, region) {
        let pickupBlock = $('.cartPage .pickupBlock'),
            pickUpContainer = $('.cartPage .pickupBlock .pickUpContainer');

        $.ajax({
            method: 'POST',
            url: `${window.location.origin}/${window.globalLang}/pickupStores`,
            beforeSend: function () {
                pickupBlock.addClass('loading');
            },
            data: {
                countryId: country.val(),
                regionId: region !== undefined ? region.val() : ''
            },
        }).success(function (response) {
            pickupBlock.removeClass('loading');

            if (response.msg)
                toastr[response.msg.type](response.msg.e);

            if (response.status && pickUpContainer.length) {
                pickUpContainer.html(response.view);

                if (response.view) {
                    setTimeout(function () {
                        $('select.select2').select2({
                            templateResult: window.select2FormatState,
                            width: 'auto'
                        });
                        $('select.select2.no-search').select2({
                            templateResult: window.select2FormatState,
                            minimumResultsForSearch: -1,
                            width: 'auto'
                        });
                    }, 100);
                }

            }
        })
            .fail(function (response) {
            pickupBlock.removeClass('loading');
            let jsonResponse = response.responseJSON;

            if (jsonResponse.msg)
                toastr[jsonResponse.msg.type](jsonResponse.msg.e);
        });
    }

    $('#by-one-click-input-cart-tabs').on('keyup', function () {

        if ( $(this).val().length > 3) {
            $('#buy-one-click-cart-tabs').attr('disabled', false)
        } else {
            $('#buy-one-click-cart-tabs').attr('disabled', true)
        }
    
    });


})(jQuery);