(function($) {

	$(document).ready(function() {
        
        /*** open/close cart container on click ***/
        $(".header .cartContainerOnHeader .cartBtn").on("click", function(e){

          $(this).parent().find('.cartResultContainer').toggle();
          $(".header .profileLinksContainer").hide();
          $(".header .dropdownMenu").removeClass("active");
          e.stopPropagation();
        })

        /*** close cart container ***/
        $(document).on("click", function(e) {
          if (!$(".header .cartContainerOnHeader .cartResultContainer").has(e.target).length && !$(".header .cartContainerOnHeader .cartResultContainer").is(e.target)) {
            $(".header .cartContainerOnHeader .cartResultContainer").hide();
          }
		    });

        // $(".cartBtn").click(function (e) {
        //
        //     $(".cartContainerOnHeader ").toggleClass("color-active");
        // });
    })

})(jQuery);