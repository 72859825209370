(function($) {

    $(document).ready(function() {

        /*** Drop down with categories ***/
        $(".searchResultsContainer .dropDownTitle").on("click", function(){
            $(this).toggleClass("active").next().toggleClass("active");
        })

        // button active disabled //by number phone search
        $('#input-number').on('keyup', function () {

            if ( $(this).val().length > 3) {
                $('#number-button').attr('disabled', false)
            } else {
                $('#number-button').attr('disabled', true)
            }

        });
    })

})(jQuery);