(function ($) {

    $(document).ready(function () {
        let mainSliderBlock = $('.mainSliderOnIndexPage');
        if (mainSliderBlock.length)
            mainSliderBlock.slick({
                autoplay: true,
                autoplaySpeed: 4000,
                infinite: true,
                dots: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1
            });
    })

})(jQuery);