(function($) {

	$(document).ready(function() {
        
        /*** open/close dropdown ***/
        // $(".dropdown .curerntChoice").click(function(e){
        //     $(".dropdown .dropdownMenu").not($(this).siblings(".dropdownMenu")).removeClass("active");
        //     $(this).siblings(".dropdownMenu").toggleClass("active");
        //     $(".header .cartResultContainer, .header .profileLinksContainer").hide();
        //     e.stopPropagation();
        // })

        $(".dropdown .curerntChoice").click(function(e){
            $(".dropdown .dropdownMenu").not($(this).siblings(".dropdownMenu")).removeClass("active");
            $(this).toggleClass("active").siblings(".dropdownMenu").toggleClass("active");
            $(".header .cartResultContainer, .header .profileLinksContainer").hide();
            e.stopPropagation();
        })

        /*** close dropdown ***/
        $(document).on("click", function(e) {
            if (!$(".dropdown .dropdownMenu").has(e.target).length && !$(".dropdown .dropdownMenu").is(e.target)) {
              $(".dropdown .dropdownMenu, .dropdown .curerntChoice ").removeClass("active");
            }
        });

        /*** Change currencies ***/
        $('.dropdown.currency .dropdownMenu a').on('click', function (e) {
            e.preventDefault();
            let itemId = $(this).data('item-id');

            $.ajax({
                method: "POST",
                url: `${window.location.origin}/${window.globalLang}/setCurrency`,
                data: {
                    itemId: itemId
                }
            })
                .success(function (response) {
                    if (response.status)
                        window.location.href = window.location.href.split('?')[0];
                })
        });


    })

})(jQuery);