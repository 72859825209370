(function ($) {

    $(document).ready(function () {

        let subscribeDashboardBlock = $('#subscribe-newsletter-dashboard');
        if (subscribeDashboardBlock.length)
            $(document).on('change', '#subscribe-newsletter-dashboard input[type=checkbox]', function () {
                let data = {},
                    subscribeCheckboxes = subscribeDashboardBlock.find('input[type=checkbox]'),
                    subscribeContainer = $(this).closest('.subscribeContainer');

                if(subscribeCheckboxes.length)
                    subscribeCheckboxes.map((index, item) => {
                        data[$(item).attr('name')] = $(item).prop('checked') ? 1 : 0;
                    });

                $.ajax({
                    method: 'post',
                    url: `${window.location.origin}/${window.globalLang}/subscribe`,
                    beforeSend: function () {
                        subscribeContainer.addClass('loading');
                    },
                    data: data
                })
                    .success(function (response) {
                        subscribeContainer.removeClass('loading');

                        if (response.msg && !response.validator)
                            toastr[response.msg.type](response.msg.e);

                        if (response.validator && response.msg) {
                            $.each(response.msg.e, function (ObjNames, ObjValues) {
                                toastr[response.msg.type](ObjValues);
                            });
                        }

                    })
                    .fail(function (response) {
                        subscribeContainer.removeClass('loading');

                        let jsonResponse = response.responseJSON;

                        if (jsonResponse.msg)
                            toastr[jsonResponse.msg.type](jsonResponse.msg.e);
                    });

            });

    });

})
(jQuery);