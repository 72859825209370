(function ($) {

    $(document).ready(function () {

        /*** Toggle product main info in order history ***/
        $(document).on("click", ".orderHistoryBlock .shortInfo", function(){
            $(this).toggleClass("active").next(".mainInfo").stop().slideToggle(200);

        });

    });

})
(jQuery);