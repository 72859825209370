(function ($) {

    let loaderGif = $("#loading-catalog");
    let resetFilter = false;

    $(document).ready(function () {

        /*** Show all checkbox options ***/
        $(".filterList .filter-item .overflowContainer").each(function () {
            let overflowContainer = $(this);
            let checkboxContainer = $(this).children(".checkboxContainer");

            if (overflowContainer.height() < checkboxContainer.height() - 1) {

                $(overflowContainer).siblings(".showAllbtn").addClass("visible");

                $(this).siblings(".showAllbtn").on("click", function () {
                    $(this).siblings(".overflowContainer").toggleClass("fullHeight");
                    $(this).children().first().toggle();
                    $(this).children().last().toggle();
                })
            }
        });

        /*** Open/close filter on mobile ***/
        $(".sortList .leftBlock .filterOpenBtn").on("click", function () {
            $(".filterList").addClass("active");
            $(".catalogContainer .mobileFilterMaskForClose, .filter_overlay").addClass("active");
        })
        $(".catalogContainer .mobileFilterMaskForClose, .filter_overlay").on("click", function () {
            $('.filter_overlay').removeClass("active");
            $(".filterList").removeClass("active");
            $(this).removeClass("active");
        });

        $(".close_filter_mobile").click(function () {
            $('.filter_overlay').removeClass("active");
            $(".filterList").removeClass("active");
            $(this).removeClass("active");
        });

        $(document).on('change', '#filter-form input:not(input[type=text])', function () {
            let form = $('#filter-form').get(0);
            if ($(form).find('button.submit-filter-btn').length > 0)
                return false;

            // if (window.filterTimeout !== undefined)
            //     clearTimeout(window.filterTimeout);

            // window.filterTimeout = setTimeout(() => {
                filterForm(form);
            // }, 1000);
        });

        $(document).on('click', '#filter-form .filter-btn button', function (e) {
            e.preventDefault();

            let form = $('#filter-form').get(0);
            resetFilter = $(this).data('action') === 'reset';

            filterForm(form, $(this).data('action') === 'reset');
        });

        initRanges();

        $(document).on('change', '#sort-form select, #sort-form input', function () {
            let form = $('#filter-form').get(0);

            filterForm(form);
        });

    });


    function globalRange(min, max, itemId) {
        let form = $('#filter-form').get(0);
        let slider = $('#' + itemId).get(0);
        let fromFieldId = itemId.replace(/(-range)$/, '') + '-from';
        let toFieldId = itemId.replace(/(-range)$/, '') + '-to';
        let fieldFrom = $(slider).siblings('.range-values').find('#' + fromFieldId);
        let fieldTo = $(slider).siblings('.range-values').find('#' + toFieldId);
        let fieldFromVal = fieldFrom.val();
        let fieldToVal = fieldTo.val();

        if (min === max)
            min = max - 1;

        if (fieldFromVal === fieldToVal)
            fieldFromVal = fieldToVal - 1;

        let minVal = min === fieldFromVal ? min : fieldFromVal;
        let maxVal = max === fieldToVal ? max : fieldToVal;

        noUiSlider.create(slider, {
            start: [Math.floor(minVal), Math.ceil(maxVal)],
            connect: true,
            margin: 1,
            range: {
                'min': Math.floor(min),
                'max': Math.ceil(max)
            }
        });
        //
        slider.noUiSlider.on('update', function (values, handle) {
            handle ? fieldTo.val(Math.ceil(values[handle])) : fieldFrom.val(Math.floor(values[handle]));
        });

        fieldFrom.get(0).addEventListener('change', function () {
            slider.noUiSlider.set([this.value, null]);
        });

        fieldTo.get(0).addEventListener('change', function () {
            slider.noUiSlider.set([null, this.value]);
        });

        slider.noUiSlider.on('set', function (values, handle) {

            if ($(form).find('button.submit-filter-btn').length > 0)
                return false;

            if (!resetFilter)
                filterForm(form);
        });

    }


    function filterForm(parentThat, resetForm) {
        let formId = $(parentThat).attr('id');
        let form = $('#' + formId);
        let sortForm = $('#sort-form');

        if (form.length < 1) {
            return;
        }

        form.submit(function (event) {
            event.preventDefault();
        });

        let serializedForm = $(form).find("select, input").serializeArray();

        serializedForm = serializedRanges(serializedForm);

        let serializedSortForm = [];

        if (sortForm.length > 0)
            serializedSortForm = sortForm.find('select, input').serializeArray();

        if (serializedSortForm.length > 0)
            serializedSortForm.map((value) => {
                serializedForm.push({name: value.name, value: value.value});
            });

        if (resetForm)
            if (serializedForm.length > 0)
                serializedForm = serializedForm.filter(function (item) {
                    return item.name === 'categoryId' || item.name.startsWith('sort');
                });

        $.ajax({
            method: "POST",
            url: form.attr('action'),
            delay: 250,
            beforeSend: function () {
                loaderGif.fadeIn();
            },
            data: serializedForm,
            success: function (response) {
                loaderGif.fadeOut();

                if (response.status) {

                    let pushUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + response.pushUrl;
                    window.history.pushState({path: pushUrl}, '', pushUrl);

                    if ($(document).find('.products-list').length > 0) {
                        $(document).find('.products-list').html(response.view);
                        if ($(document).find('.products').length > 0)
                            $(document).find('.products').toggleClass('empty', response.count === 0);
                    }

                    if (response.filter && $(document).find('#filter-form').length > 0) {
                        $(document).find('#filter-form').html(response.filter);
                        initRanges();
                    }

                    if (resetForm) {
                        console.log('intra');
                        form.find('input[type=checkbox]').prop('checked', false);
                        form.find('input[type=radio]').prop('checked', false);
                        let formRanges = form.find('.range');

                        if (formRanges.length > 0)
                            formRanges.map((key, value) => {
                                $(value).siblings('.range-values').find('.range-min input').val($(value).data('min'));
                                $(value).siblings('.range-values').find('.range-max input').val($(value).data('max'));

                                let slider = $('#' + $(value).attr('id')).get(0);
                                slider.noUiSlider.updateOptions({
                                    start: [Math.floor($(value).data('min')), Math.ceil($(value).data('max'))]
                                });
                            });

                        resetFilter = false;
                    }

                    initLazyLoader();

                }
            },
            error: function () {
                loaderGif.fadeOut();
            }
        });

    }

    function initRanges() {
        $('.range').map((key, value) => {
            let minValue = $(value).attr('data-min');
            let maxValue = $(value).attr('data-max');

            globalRange(minValue, maxValue, $(value).attr('id'));
        });
    }

    function serializedRanges(serializedForm) {
        let ranges = $('.range');
        if (ranges.length)
            ranges.map((key, value) => {
                let fromFieldId = $(value).attr('id').replace(/(-range)$/, '') + '-from';
                let toFieldId = $(value).attr('id').replace(/(-range)$/, '') + '-to';
                let filedFrom = $(value).siblings('.range-values ').find(`#${fromFieldId}`);
                let filedTo = $(value).siblings('.range-values ').find(`#${toFieldId}`);

                if (filedFrom.length && filedTo.length && Math.floor(parseFloat(filedFrom.val())) === parseFloat($(value).data('min')) && Math.ceil(parseFloat(filedTo.val())) === parseFloat($(value).data('max')))
                    serializedForm = serializedForm.filter((item) => {
                        return (item.name !== filedFrom.attr('name') && item.name !== filedTo.attr('name'));
                    });
            });

        return serializedForm;
    }

    window.addEventListener('popstate', function (e) {

        if (e.state === null || !Object.keys(e.state).length) {
            window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname;
        } else {
            window.location.href = e.state.path;
        }

    });

})
(jQuery);